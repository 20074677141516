const body = document.querySelector('body')
const menuIcon = document.querySelector('.hamburger')
const navbar = document.querySelector('.navbar')
const toggle = document.querySelector('.toggle input')

menuIcon.onclick = () => {
  menuIcon.classList.toggle('hamburger--active')
  navbar.classList.toggle('navbar--active')
}

body.setAttribute('data-theme', sessionStorage.getItem('theme') || 'dark')
toggle.checked = sessionStorage.getItem('theme') === 'light'

toggle.addEventListener("change", function handleThemeChange(){
  body.setAttribute('data-theme', this.checked ? 'light' : 'dark')
  sessionStorage.setItem('theme', this.checked ? 'light' : 'dark')
})

const sections = document.querySelectorAll('section')
const navLinks = document.querySelectorAll('header nav a')

window.onscroll = () => {
  sections.forEach(section => {
    const top = window.scrollY
    const offset = section.offsetTop - 100
    const height = section.offsetHeight
    const id = section.getAttribute('id')

    if (top >= offset && top < offset + height) {
      navLinks.forEach(link => {
        link.classList.remove('navbar__item--active')
        document.querySelector(`header nav a[href*=${id}]`).classList.add('navbar__item--active')
      })
      section.classList.add('u-section-animation')
    }
  })


  const header = document.querySelector('.header')
  const footer = document.querySelector('footer')

  header.classList.toggle('header--sticky', window.scrollY > 100)

  if (window.innerHeight + window.scrollY >= document.scrollingElement.scrollHeight) {
    footer.classList.add('u-section-animation')
  }

  menuIcon.classList.remove('hamburger--active')
  navbar.classList.remove('navbar--active')
}

document.querySelector('.js-section-skills-button').addEventListener("click", function handleButtonClick(){
  this.classList.toggle('section-skills__button--hide')
  document.querySelector('.js-section-skills-toggle').classList.toggle('section-skills__toggle--active')
})

document.getElementById('contact-form').addEventListener('submit', function handleSubmit(event) {
  event.preventDefault();
  const form = this;
  const formData = new FormData(form);

  fetch('../mail.php', {
    method: 'POST',
    body: formData
  })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        form.reset()
        document.getElementById('contact-alert').classList.add('section-contact__alert--active')

        setTimeout(() => {
          document.getElementById('contact-alert').classList.remove('section-contact__alert--active')
        }, 5000)
      }
    })
    .catch(error => {
      console.error('Error:', error);
    });
});
